<script setup lang="ts">
import EmailTemplates from './config/EmailTemplates.vue';
import Info from './config/Info.vue';
import Settings from './config/Settings.vue';
import Tools from './config/Tools.vue';

const configTabs = [
  {
    label: 'Company Info',
    description:
      'Add your company information to help the agent personalize its responses.',
    icon: 'i-ph-info',
    slot: 'info',
  },
  {
    label: 'Email Templates',
    description:
      'Add your email templates to help the agent personalize its responses.',
    icon: 'i-ph-envelope',
    slot: 'email',
  },
  {
    label: 'Tools',
    description: 'Add your tools to help the agent personalize its responses.',
    icon: 'i-ph-wrench',
    slot: 'tools',
  },
  {
    label: 'Settings',
    icon: 'i-ph-gear',
    slot: 'settings',
    disabled: true,
  },
];
</script>
<template>
  <UTabs
    :items="configTabs"
    orientation="vertical"
    :ui="{
      wrapper:
        'flex items-start gap-8 px-4 rounded-xl divide-x border border-gray-200',
      list: {
        background: 'bg-transparent',
        width: 'w-64',
        marker: {
          background: 'bg-transparent',
          rounded: 'rounded-none',
          base: ' border-b-4 border-primary-300',
          shadow: 'shadow-none',
        },
        tab: {
          base: '!justify-start mt-4 text-lg',
          font: 'font-bold',
          icon: 'w-6 h-6',
          padding: 'pb-6 pt-2',
        },
      },
      container: 'pl-8 pr-5 !mt-0 pt-5 pb-8',
    }"
  >
    <template #info="{ item }">
      <span class="flex flex-col gap-2 mb-5 items-start">
        <p class="main-title">{{ item.label }}</p>
        <p class="body dimmed">
          {{ item.description }}
        </p>
      </span>
      <Info />
    </template>
    <template #email="{ item }">
      <span class="flex flex-col gap-2 mb-5 items-start">
        <p class="main-title">{{ item.label }}</p>
        <p class="body dimmed">
          {{ item.description }}
        </p>
      </span>
      <EmailTemplates />
    </template>
    <template #tools="{ item }">
      <span class="flex flex-col gap-2 mb-5 items-start">
        <p class="main-title">{{ item.label }}</p>
        <p class="body dimmed">
          {{ item.description }}
        </p>
      </span>
      <Tools />
    </template>
    <template #settings="{ item }">
      <span class="flex flex-col gap-2 mb-5 items-start">
        <p class="main-title">{{ item.label }}</p>
        <p class="body dimmed">
          {{ item.description }}
        </p>
      </span>
      <Settings />
    </template>
  </UTabs>
</template>
