<script setup lang="ts">
import CreateEmailTemplateModal from '~/components/modals/CreateEmailTemplateModal.vue';
const modal = useModal();
const workspaceStore = useWorkspaceStore();
const { workspaceId } = storeToRefs(workspaceStore);

const { data: templates } = useApi(
  `/api/workspaces/${workspaceId.value}/emails`,
  { key: 'emailTemplates' },
);

const userTemplates = computed(() => templates.value?.custom ?? []);
const officialTemplates = computed(() => templates.value?.official ?? []);

const handleEdit = (emailId: string) => {
  modal.open(CreateEmailTemplateModal, { emailId });
};
</script>
<template>
  <div class="flex flex-col items-start gap-3">
    <p class="subtitle">Official Templates</p>
    <div class="flex flex-wrap justify-start gap-6">
      <div
        v-for="template in officialTemplates"
        :key="template.id"
        class="flex flex-col justify-start p-4 items-start gap-2 border border-gray-200 bg-white rounded-xl h-44 w-80"
      >
        <span class="flex w-full justify-between items-baseline">
          <p class="subtitle truncate">{{ template.name }}</p>
          <img src="../../../../public/favicon.png" style="width: 1rem" />
        </span>
        <p class="caption line-clamp-4">{{ template.body }}</p>
      </div>
    </div>
    <p class="subtitle mt-4">Your Templates</p>
    <div v-if="userTemplates?.length" class="contents">
      <div class="flex flex-wrap justify-start gap-6">
        <div
          v-for="template in userTemplates"
          :key="template.id"
          class="flex flex-col justify-start p-4 items-start gap-2 border border-gray-200 bg-white rounded-xl h-44 w-80"
        >
          <span class="flex w-full justify-between">
            <p class="subtitle">{{ template.name }}</p>
            <UButton
              :padded="false"
              color="gray"
              variant="link"
              size="xs"
              icon="i-ph-note-pencil"
              @click="handleEdit(template.id)"
            />
          </span>
          <p class="caption line-clamp-4">{{ template.body }}</p>
        </div>
      </div>
      <UButton
        icon="i-ph-plus-circle"
        label="Add Templates"
        color="white"
        @click="() => modal.open(CreateEmailTemplateModal)"
      />
    </div>
    <div v-else class="flex flex-col w-full py-6 gap-2 items-center">
      <UIcon name="i-ph-smiley-melting" class="text-gray-400 text-3xl" />
      <p class="subtitle">You haven't created any email templates yet</p>
      <p class="body dimmed">Create one to get started</p>
      <UButton
        label="Create new"
        icon="i-ph-plus"
        color="white"
        @click="() => modal.open(CreateEmailTemplateModal)"
      />
    </div>
  </div>
</template>
