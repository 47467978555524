<script setup lang="ts">
import AppInput from '~/components/app/AppInput.vue';

const { emailId } = defineProps<{
  emailId: string;
}>();

const modal = useModal();
const workspaceStore = useWorkspaceStore();
const agentStore = useAgentsStore();

const { data: template } = useApi(
  `/api/workspaces/${workspaceStore.workspaceId}/emails/${emailId}`,
  { immediate: !!emailId },
);

const state = reactiveComputed(() => {
  return {
    name: template.value?.name ?? undefined,
    subject: template.value?.subject ?? undefined,
    body: template.value?.body ?? undefined,
    instructions: template.value?.instructions ?? undefined,
  };
});

const onSubmit = async () => {
  const endpoint = emailId
    ? `/api/workspaces/${workspaceStore.workspaceId}/emails/${emailId}`
    : `/api/workspaces/${workspaceStore.workspaceId}/emails`;
  const method = emailId ? 'PATCH' : 'POST';

  await useApi(endpoint, {
    method,
    body: { ...state },
  });

  await refreshNuxtData('emailTemplates');
  modal.close();
};
</script>
<template>
  <UModal :ui="{ width: 'sm:max-w-3xl' }">
    <UCard
      :ui="{
        base: 'w-full',
        body: {
          base: 'flex flex-col gap-6 items-start max-h-[60vh] overflow-y-scroll justify-start',
        },
        footer: {
          base: 'flex w-full justify-between',
        },
      }"
    >
      <template #header>
        <p class="title">{{ emailId ? 'Edit' : 'Create an' }} email template</p>
      </template>

      <UFormGroup label="Template name" class="w-full" size="xl">
        <UInput v-model="state.name" />
      </UFormGroup>
      <AppInput
        v-model="state.subject"
        class="w-full"
        :option="agentStore.agentGraph?.inputs?.templatesubject"
      />
      <AppInput
        v-model="state.body"
        class="w-full"
        :option="agentStore.agentGraph?.inputs?.templatebody"
      />
      <AppInput
        v-model="state.instructions"
        class="w-full"
        :option="agentStore.agentGraph?.inputs?.templateDescription"
      />

      <template #footer>
        <UButton label="Cancel" color="white" @click="modal.close" />
        <UButton label="Save template" @click="onSubmit" />
      </template>
    </UCard>
  </UModal>
</template>
